<template>
    <div class="warpper">
      <Head/>
      <el-container class="content">
        <Side></Side>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <Foot/>
    </div>

</template>

<script>

import Head from '@components/Head'
import Foot from '@components/Foot'
import Side from '@components/Side'


export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    Side,Head,Foot
},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.warpper {
  width: 100%;
  .content{
    width: 100%;
    height: calc(100vh - 100px);
    .el-main{
      margin: 0;
      padding: 0;
      background-color: rebeccapurple;
    }
    
  }
}
</style>
