<template>
  <div class="description">
    <div class="left-top-block"></div>
    <div class="top-line"></div>
    <div class="right-top-block"></div>

    <div class="right-bottom-block"></div>
    <div class="bottom-line"></div>
    <div class="left-bottom-block"></div>
    <div class="content">
      <div class="yesterday"><span></span> 红点表示昨日累积的验伪行为</div>
      <div class="today"><span></span> 蓝点表示今天累积的验伪行为</div>
      <div class="animate"><span></span> 动画表示实时验伪行为</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenDescription",
  props: {
    title: String,
    rect: Object,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.description {
  position: absolute;
  overflow: hidden;

  width: 260px;
  height: 108px;
  left: calc(50% - 130px);
  bottom: 80px;
  // background: rebeccapurple;
  .temp-block {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.15);
  }
  .left-top-block {
    left: 0;
    top: 0;
    .temp-block();
  }
  .right-top-block {
    right: 0;
    top: 0;
    .temp-block();
  }
  .right-bottom-block {
    right: 0;
    bottom: 0;
    .temp-block();
  }
  .left-bottom-block {
    left: 0;
    bottom: 0;
    .temp-block();
  }

  .temp-line {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.15);
    //  background-color: red;
  }

  .top-line {
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
    .temp-line();
  }
  .bottom-line {
    bottom: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
    .temp-line();
  }

  .content {
    margin: 8px 35px;
    width: calc(100% - 70px);
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // align-items: center;
    color: #fff;
    font-size: 10px;
    div {
      display: flex;
      align-items: center;
    }
    .yesterday {
      span {
        display: inline-block;
        background: #b81b1b;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
    .today {
      span {
        display: inline-block;
        background: #5d83ff;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
    .animate {
      span {
        display: inline-block;
        background: radial-gradient(
          rgba(93, 131, 255, 1),
          rgba(93, 131, 255, 0.1)
        );
        width: 14px;
        height: 14px;
        border-radius: 7px;
        margin-right: 8px;
      }
    }

    // background-color: aqua;
  }
}
</style>



