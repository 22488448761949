export default  [
    {
        title:'数据看板',
        icon:'el-icon-data-line',
        route:'board'
    },
    {
        title:'数据大屏',
        icon:'el-icon-monitor',
        route:'screen'
    },
  
    {
        title:'数据报表',
        icon:'el-icon-pie-chart',     
        route:'report' 
    }
]