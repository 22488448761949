<template>
  <ScreenBoard title="用户画像" :rect="rect">
    <div class="customer-portrait">
      <div id="customer-age-echart">{{echartAge}}</div>
      <div class="customer-age-sex-line"></div>
      <div class="customer-sex-echart">
        <div class="echart-title">性别分布</div>
        <div class="echart-content">
          <div class="male" :style="{ 'flex-grow': sexsInfo[0]?sexsInfo[0].value:1 }">
            <div class="sex-echart"></div>
            <div class="sex-title"><i class="el-icon-male" /> {{sexsInfo[0]?sexsInfo[0].name:''}}</div>
            <div class="sex-value">{{sexsInfo[0]?sexsInfo[0].value:''}}, <span class="percent">{{sexsInfo[0]?sexsInfo[0].percent:''}}</span></div>
          </div>

          <div class="female" :style="{ 'flex-grow': sexsInfo[1]?sexsInfo[1].value:1 }">
            <div class="sex-echart"></div>
            <div class="sex-title"><i class="el-icon-female" /> {{sexsInfo[1]?sexsInfo[1].name:''}}</div>
            <div class="sex-value">{{sexsInfo[1]?sexsInfo[1].value:''}}, <span class="percent">{{sexsInfo[1]?sexsInfo[1].percent:''}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </ScreenBoard>
</template>

<script>
import ScreenBoard from "@components/ScreenBoard";
export default {
  name: "ScreenCustomerPortrait",
  props: {
    customersData: Object,
  },
  computed: {
    agesName() {
      return this.customersData.customersAge.map((item) => {
        return `${item.name}`;
      });
    },
    agesCount() {
      return this.customersData.customersAge.map((item) => {
        return ` ${item.value}`;
      });
    },
    sexsInfo() {
      let total = this.customersData.customersSex.reduce((sumTemp, item) => {
        return sumTemp + item.value;
      }, 0);
      return this.customersData.customersSex.map((item) => {
        return {
          percent: (100*item.value/total).toFixed(1) + '%',
          ...item
        };
      });
    },
    echartAge(){
     let value = `${this.agesName.length}-${this.agesCount.length}`
     this.updateEchart()
     return value?'':''
   }
  },
  methods:{
    updateEchart(){
       // 绘制图表
    this.echartCustomerAge?.setOption({
      title: {
        text: "     年龄分布",
        textStyle: {
          color: "#FFF",
          fontSize: 14,
        },
      },
      barWidth: 15,
      grid: {
        left: "35px",
        right: "35px",
        top: "35px",
        bottom: "50px",
      },
      xAxis: {
        data: this.agesName,
        axisLine: {
          show: false,
        },
        axisTick: {
          alignWithLabel: true,
          show: false,
        },
        axisLabel: {
          margin: 22,
          color: "#FFF",
        },
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          interval: 0,
          lineStyle: {
            color: ["#ccc"],
            type: "dashed",
            opacity: 0.15,
          },
        },
      },
      series: [
        {
          name: "年龄",
          type: "bar",
          data: this.agesCount,
          smooth: true,
          color: {
            type: "bar",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#5D83FF", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#06E8DC", // 100% 处的颜色
              },
            ],
            globalCoord: false, // 缺省为 false
          },
        },
      ],
    });
    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.echartCustomerAge = this.$echarts.init(
      document.getElementById("customer-age-echart")
    );
   
  },
  data() {
    return {
      rect: {
        bottom: "80px",
        left: "56px",
        height: "570px",
        width: "540px",
      },
    };
  },
  components: {
    ScreenBoard,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.customer-portrait {
  width: 100%;
  height: calc(100% - 50px);
  #customer-age-echart {
    margin-top: 5px;
    width: 100%;
    height: 70%;
  }
  .customer-age-sex-line {
    // bottom: 25%;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.15);
  }
  .customer-sex-echart {
    width: 100%;
    height: 25%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .echart-title {
      color: #fff;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
      padding: 0 20px;
      width: 100%;
      line-height: 50px;
      height: 50px;
    }
    .echart-content {
      display: flex;
      justify-content: space-around;
      // flex-direction: column;

      .male {
        margin: 0 5px 0 20px;
        display: flex;
        flex-direction: column;
        .sex-echart {
          // width: 100%;
          height: 16px;
          background-color: #06e8dc;
        }
                .percent {
              color: #06e8dc;
        }
      }

      .female {
        height: 100%;
        margin: 0 20px 0 5px;
        display: flex;
        flex-direction: column;
        .percent {
              color: #5d83ff;
        }
        .sex-echart {
          // width: 100%;
          height: 16px;
          background-color: #5d83ff;
        }
      }

      .sex-title {
        margin-top: 15px;
        color: white;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
      }
      .sex-value {
        font-weight: 400;
        margin-top: 0px;
        color: white;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
      }
    }

    // background-color: red;
  }
}
</style>



