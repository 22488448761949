import Vue from 'vue'
import router from '@/router'
import store from '@/vuex'
import plguinNotification from '@/plugins/pluginNotification'
import pluginElementUI from '@/plugins/pluginElementUI'
import pluginEcharts from '@/plugins/pluginEcharts'
import App from './App.vue'

import '@assets/iconfont/iconfont.css'
import 'animate.css';

Vue.config.productionTip = false
Vue.use(plguinNotification)
Vue.use(pluginElementUI)
Vue.use(pluginEcharts)
import '@assets/theme.scss'

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
