<template>
    <div class="warp">
            Report
    </div>

</template>

<script>

export default {
  name: "Report",
  props: {
    msg: String,
  },
 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.warp {
  width: 100%;
}
</style>
