<template>
  <div id="map_container"></div>
</template>

<script>
import mapJson from "@map/mapStyleJson";
export default {
  name: "ScreenMap",
  props: {
    mapHistoryData: Array,
    mapCurrentData: Array,
  },
  computed: {
    dataHistory() {
      console.log('aiayuoayoi')
      let temp = this.mapHistoryData
        ?.filter((item) => {
          return item.longitude && item.latitude;
        })
        .map((item) => {
          return {
            geometry: {
              type: "Point",
              coordinates: [item.longitude, item.latitude],
            },
          };
        });

        // this.layerHistory?.setData(temp); 
        return [...temp]
    },
    dataCurrent() {
      return this.mapCurrentData
        ?.filter((item) => {
          return item.longitude && item.latitude;
        })
        .map((item, index) => {
          return {
            geometry: {
              type: "Point",
              coordinates: [item.longitude, item.latitude],
            },
            index:index+1,
          };
        });
    },

    mapInfos() {

      let value = `${this.dataHistory?.length}`;
      // this.layerCurrent?.setData([...this.dataCurrent]);
      // this.layerHistory?.setData(this.dataHistory);
      // // this.layerActive?.setData(this.dataActive);
      console.log('阿偶OAu偶啊欧诺')
      // this.layerSpark?.setData(this.dataActive);
      // this.layerlabel?.setData(this.dataActive);
      return value ? "" : "";
    },
  },
  mounted() {
    let bmap = new BMapGL.Map("map_container", {
      restrictCenter: false,
      style: mapJson,
    });

    bmap.centerAndZoom(new BMapGL.Point(105.403119, 38.028658), 5);
    bmap.disableDragging();
    bmap.enableResizeOnCenter();
    bmap.disableContinuousZoom();
    bmap.disableDoubleClickZoom();
    // bmap.enableKeyboard();
    // bmap.enableScrollWheelZoom();
    // bmap.enableInertialDragging();
    // bmap.enableContinuousZoom();

    bmap.setHeading(10); //设置地图旋转角度
    bmap.setTilt(45); //设置地图的倾斜角度

    // 2. 创建MapVGL图层管理器
    let view = new mapvgl.View({
      map: bmap,
    });
    // 3. 创建可视化图层，并添加到图层管理器中
    // this.layerHistory = new mapvgl.CircleLayer({
    //   type: "bubble",
    //   color: '#5d83ff',
    //   size: 10,
    //   radius: 20,
    //   // duration:10,
    // });
    this.layerHistory = new mapvgl.PointLayer({
      blend: "lighter",
      size: 7,
      color: "rgba(184, 27, 27,0.8)",
      renderOrder:10,
      
    });
    view.addLayer(this.layerHistory);
    

    this.layerCurrent = new mapvgl.PointLayer({
      blend: "lighter",
      size: 7,
      color: "rgba(93, 131, 255,0.8)",
       renderOrder:20,
    });
    view.addLayer(this.layerCurrent);

    this.layerActive = new mapvgl.RippleLayer({
      // type: "Point",
      color: "rgba(93, 131, 255, 0.8)",
      size: 80,
      renderOrder:30,
      // radius: 15,
      // duration:20,
    });
    view.addLayer(this.layerActive);

    this.layerSpark = new mapvgl.SparkLayer({
      color: "yellow",
      height: 3000000,
       renderOrder:40,
    });
    view.addLayer(this.layerSpark);


    
    // 5. 关联图层与数据，享受震撼的可视化效果
    // this.layerHistory.setData(data);
    // this.layerSpark.setData(data);

    // this.layerlabel = new mapvgl.TextLayer({
    //   color: "#fff",
    //   fontFamily: "Microsoft Yahei",
    //   fontSize: 13,
    //   fontWeight: 500,
    //   // flat: false,
    //   collides: true,
    //   offset: [0, 0],
    //   // padding: [2, 2],
    //   // margin: [0, 0],
    // });

    // view.addLayer(this.layerlabel);

      // console.log('8989898',this.dataCurrent)
      // this.layerCurrent.setData(this.dataCurrent);
      // this.layerHistory.setData(this.dataHistory);


    setInterval(() => {
      if (this.dataCurrent?.length) {
        if (this.dataActive.length) {
          let index = this.dataActive[this.dataActive.length - 1].index;
          if (index > 4) {
            this.dataActive = this.dataCurrent
              .slice(index - 4, index + 1)
          } else {
            this.dataActive = this.dataCurrent
              .slice(0, index + 1)
          }
        } else {
          let item = this.dataCurrent[0];
          this.dataActive.push(item);
        }
      }
      this.layerHistory?.setData(this.dataHistory);
      this.layerCurrent?.setData(this.dataCurrent);
      setTimeout(() => {
        this.layerActive?.setData(this.dataActive);
        this.layerSpark?.setData(this.dataActive);
      }, 2000);

    }, 5000);
  },
  data() {


  


    return {
      layerHistory: null,
      layerCurrent: null,
      layerActive: null,
      layerSpark:null,
      // layerlabel:null,
      dataActive: [],
      indexActive: 0,
    };
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#map_container {
  // position: absolute;
  width: 100%;
  height: 100%;
}
</style>



