<template>
  <div class="board" :style="rect">
    <div class="left-top-block"></div>
    <div class="top-line"></div>
    <div class="right-top-block"></div>
    <div class="right-line"></div>
    <div class="right-bottom-block"></div>
    <div class="bottom-line"></div>
    <div class="left-bottom-block"></div>
    <div class="left-line"></div>
    <div class="content">
      <div class="title">
        <span>{{ title }}</span>
        <div class="title-left-block"></div>
        <div class="title-line"></div>
        <div class="title-right-block"></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenBoard",
  props: {
    title: String,
    rect: Object,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.board {
  position: absolute;
  overflow: hidden;
  .temp-block {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.15);
  }
  .left-top-block {
    left: 0;
    top: 0;
    .temp-block();
  }
  .right-top-block {
    right: 0;
    top: 0;
    .temp-block();
  }
  .right-bottom-block {
    right: 0;
    bottom: 0;
    .temp-block();
  }
  .left-bottom-block {
    left: 0;
    bottom: 0;
    .temp-block();
  }

  .temp-line {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.15);
    //  background-color: red;
  }

  .top-line {
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
    .temp-line();
  }
  .bottom-line {
    bottom: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
    .temp-line();
  }
  .left-line {
    left: 0;
    top: 15px;
    height: calc(100% - 30px);
    width: 1px;
    .temp-line();
  }
  .right-line {
    right: 0;
    top: 15px;
    height: calc(100% - 30px);
    width: 1px;
    .temp-line();
  }
  .content {
    margin: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    background: linear-gradient(
      180deg,
      rgba(93, 131, 255, 0.15),
      rgba(93, 131, 255, 0)
    );
    .title {
      color: rgba(255, 255, 255, 0.45);
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      line-height: 48px;
      position: relative;

      span {
        padding: 0 20px;
        font-size: 13px;
        font-weight: 500;
      }

      .title-left-block {
        position: absolute;
        bottom: 0;
        left: 0;
        .temp-block();
      }

      .title-right-block {
        position: absolute;
        bottom: 0;
        right: 0;
        .temp-block();
      }

      .title-line {
        bottom: 2px;
        left: 15px;
        width: calc(100% - 30px);
        height: 1px;
        .temp-line();
      }
    }

    // background-color: aqua;
  }
}
</style>



