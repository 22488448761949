<template>
    <div class="warp">
        <span class="copyright">©2022 杭州沃朴物联科技有限公司 版权所有</span>
       <span class="filing">浙ICP备14019604号</span>
    </div>

</template>

<script>




export default {
  name: "Foot",
  props: {
    msg: String,
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 40px;
  text-align: center;


  .copyright {
    display: block;
    font-size: 14px;
  }
    .filing {
    display: block;
    font-size: 11px;
  }
}
</style>
