import axios from "axios";
import { API_HOST,WS_HOST } from "@/configs/config"
/**
 * withCredentials 配置为 True 时后端配置跨域 Access-Control-Allow-Origin不能为 " * ",必须是你的源地址
 */
// axios.defaults.withCredentials = true
// 网络请求
/*
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 环境变量base接口地址 url = base url + $request url
    withCredentials: true, // 跨域请求时发送Cookie
    timeout: 60000, // 请求超时
    headers: {
      "Content-Type": "application/json; charset=UTF-8;",
      "Referer": "https://servicewechat.com/wx438760012de48d74/devtools/page-frame.html"
    }
  });
*/
// 请求基础封装方法
function $request(method,url,params={}){
    return new Promise((resolve, reject) => {
        axios[method](`${API_HOST}/${url}`,  params).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

// 请求POST封装方法
function $Post(url,params={}){

    return new Promise((resolve, reject) => {
        $request('post',url, params).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}


// 请求Get封装方法
function $Get(url,params={}){
    return new Promise((resolve, reject) => {
        $request('get',url, params).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}

function $Download(fileName='file',path='') {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url:`${API_HOST}/public/${path}/${fileName}`,
            responseType: 'blob'
        }).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            link.download = fileName;
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href); // 释放URL 对象
            document.body.removeChild(link);

            resolve({
                code:200,
                success:true,
                message:`[ 下载成功OK ]`
            })
        }).catch(err => {
            reject({
                code:400,
                success:false,
                message:`[ ${err.message} ]`
            })

        })
    })



}


// 获取WebSocket封装方法
function $WebSocketHandle(url,params,message,success,fail){
    let socket = new WebSocket(`${WS_HOST}/${url}`)
    socket.onopen = (() =>{
        socket.send(params)
    })

    socket.onmessage = (event =>{
        console.log('event',event)
        let { state, fileNames } = JSON.parse(event.data)
        if(state === 'pending'){
            message(event.data)
        }else if(state === 'success'){
            success(fileNames)
        } else if(state === 'fail'){
            fail()
        }
    })

    socket.onerror = (() =>{
        fail()
    })


}

import * as API from '@network/api'


export {
    $Post,
    $Get,
    API,
}







