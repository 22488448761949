
export default {
    styleJson: [
      {
        featureType: 'land',
        elementType: 'geometry',
        stylers: {
          color: '#08304b',
        },
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          color: '#021019',
        },
      },
      {
        featureType: 'green',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          color: '#d0edcc',
        },
      },
      {
        featureType: 'building',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'building',
        elementType: 'geometry.fill',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'building',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#dadada',
        },
      },
      {
        featureType: 'subwaystation',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          color: '#b15454',
        },
      },
      {
        featureType: 'education',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          color: '#e4f1f1',
        },
      },
      {
        featureType: 'medical',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          color: '#f0dede',
        },
      },
      {
        featureType: 'scenicspots',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          color: '#e2efe5',
        },
      },
      {
        featureType: 'highway',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          weight: 4,
        },
      },
      {
        featureType: 'highway',
        elementType: 'geometry.fill',
        stylers: {
          color: '#f7c54d',
        },
      },
      {
        featureType: 'highway',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#fed669',
        },
      },
      {
        featureType: 'highway',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'highway',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#8f5a33',
        },
      },
      {
        featureType: 'highway',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'highway',
        elementType: 'labels.icon',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'arterial',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          weight: 2,
        },
      },
      {
        featureType: 'arterial',
        elementType: 'geometry.fill',
        stylers: {
          color: '#d8d8d8',
        },
      },
      {
        featureType: 'arterial',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#ffeebb',
        },
      },
      {
        featureType: 'arterial',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'arterial',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#525355',
        },
      },
      {
        featureType: 'arterial',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'local',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          weight: 1,
        },
      },
      {
        featureType: 'local',
        elementType: 'geometry.fill',
        stylers: {
          color: '#d8d8d8',
        },
      },
      {
        featureType: 'local',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'local',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'local',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#979c9a',
        },
      },
      {
        featureType: 'local',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'railway',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          weight: 1,
        },
      },
      {
        featureType: 'railway',
        elementType: 'geometry.fill',
        stylers: {
          color: '#949494',
        },
      },
      {
        featureType: 'railway',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'subway',
        elementType: 'geometry',
        stylers: {
          visibility: 'on',
          weight: 1,
        },
      },
      {
        featureType: 'subway',
        elementType: 'geometry.fill',
        stylers: {
          color: '#d8d8d8',
        },
      },
      {
        featureType: 'subway',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'subway',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'subway',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#979c9a',
        },
      },
      {
        featureType: 'subway',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'continent',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'continent',
        elementType: 'labels.icon',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'continent',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#333333',
        },
      },
      {
        featureType: 'continent',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'city',
        elementType: 'labels.icon',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'city',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'city',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#454d50',
        },
      },
      {
        featureType: 'city',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'town',
        elementType: 'labels.icon',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'town',
        elementType: 'labels',
        stylers: {
          visibility: 'on',
        },
      },
      {
        featureType: 'town',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#454d50',
        },
      },
      {
        featureType: 'town',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff',
        },
      },
      {
        featureType: 'districtlabel',
        elementType: 'labels',
        stylers: {
          visibility: 'off',
        },
      },
      {
        featureType: 'poilabel',
        elementType: 'labels',
        stylers: {
          visibility: 'off',
        },
      },
      {
        featureType: 'poilabel',
        elementType: 'labels.icon',
        stylers: {
          visibility: 'off',
        },
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: {
          visibility: 'off',
        },
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: {
          visibility: 'off',
        },
      },
      {
        featureType: 'boundary',
        elementType: 'geometry',
        stylers: {
          // color: '#064f85',
        },
      },
    ],
  };
  