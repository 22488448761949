import {
    Dropdown,
    DropdownMenu,
    DropdownItem,

    Container,
    Aside,
    Main,
    Menu,
    Submenu,
    MenuItem,
    Switch,
    Button,
    Image,
    Loading,
    MessageBox,
    Message,
    Notification
} from 'element-ui';

export default {
    
    install(Vue) {

        Vue.use(Dropdown);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);


        Vue.use(Container);
        Vue.use(Aside);
        Vue.use(Main);
        Vue.use(Menu);
        Vue.use(Submenu);
        Vue.use(MenuItem);
        Vue.use(Switch);
       
        Vue.use(Button);
        Vue.use(Image);

        Vue.use(Loading.directive);

        Vue.prototype.$loading = Loading.service;
        Vue.prototype.$msgbox = MessageBox;
        Vue.prototype.$alert = MessageBox.alert;
        Vue.prototype.$confirm = MessageBox.confirm;
        Vue.prototype.$prompt = MessageBox.prompt;
        Vue.prototype.$notify = Notification;
        Vue.prototype.$message = Message;
    }
}


