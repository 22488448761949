<template>
  <ScreenBoard title="近 6 个月扫码量趋势" :rect="rect">
    <div class="scan-trend">{{monthsInfo}} <div id="scan-trend-echart"></div></div>
  </ScreenBoard>
</template>

<script>
import ScreenBoard from "@components/ScreenBoard";
export default {
  name: "ScreenScanTrend",
  props: {
    monthsData: Array,
  },
  computed:{
   monthsName(){
     return this.monthsData.map(item =>{
       return `${ + item.month.split('-')[1]}` 
     })
   },
   monthsCount(){
     return this.monthsData.map(item =>{
       return ` ${item.value}` 
     })
   },
   monthsInfo(){
     let value = `${this.monthsCount.length}-${this.monthsName.length}`
     this.updateEchart()
     return value?'':''
   }
  },

  methods:{
    updateEchart(){
     this.echartScanTrend?.setOption({
      grid: {
        left: "30px",
        right: "30px",
        top: "30px",
        bottom: "50px",
      },
      xAxis: {
        data: this.monthsName,
        axisLine: {
          show: false,
        },
        axisTick: {
          alignWithLabel: true,
          show: false,
        },
        axisLabel: {
          margin: 22,
          formatter:"{value}月",
          color:'#FFF'
        },
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          interval:0,
          lineStyle: {
            color: ["#ccc"],
            type: "dashed",
            opacity: 0.15,
          },
        },
      },
      series: [
        {
          name: "扫描数量",
          type: "line",
          data: this.monthsCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#06E8DC", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#5D83FF ", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          symbol: "circle",
          symbolSize: 6,
          itemStyle: {
            borderWidth: 3,
            borderColor: "#FFF",
          },
        },
      ],
    });

    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.echartScanTrend = this.$echarts.init(
      document.getElementById("scan-trend-echart")
    );
 },
  data() {
    return {
      rect: {
        top: "80px",
        left: "56px",
        height: "320px",
        width: "540px",
      },
    };
  },
  components: {
    ScreenBoard,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.scan-trend {
  width: 100%;
  height: calc(100% - 60px);
  #scan-trend-echart {
    width: 100%;
    height: 100%;
  }
}
</style>



