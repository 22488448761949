<template>
      <div class="head">
        <div class="headbg">
          <el-image
            :src="require('@assets/imgs/bg_top_headline.png')"
          ></el-image>
        </div>
        <div class="headcontent">
          <div class="headlogo">
            <el-image :src="require('@assets/logo.png')"></el-image>
          </div>
          <div class="headtitle">扫描行为直播厅</div>
        </div>
      </div>
</template>

<script>
export default {
  name: "ScreenHead",
  props: {
    count: String,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    .head {
      position: absolute;
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
      font-size: 24px;
      font-weight: 500;
      line-height: 100px;
      .headbg {
        position: absolute;
      }
      .headcontent {
        display: flex;
        flex-direction: column;
        align-items: center;
        .headlogo {
          position: relative;
          top: 30px;
          height: 15px;
          width: 200px;
        }
        .headtitle {
          line-height: 130px;
          font-size: 25px;
        }
      }
    }
</style>



