/**
 * @author drowey
 * @email b614awei@163.com
 * @date 2022-05-15
 * @description 消息通知自定义插件
 */

export default {
    install(Vue){
        // 定义混入
        Vue.mixin({
            /**
             * @description 创建全局总线 @name $droweyBus 将 VUE 原型对象作为总线控制器
             */
            beforeCreate() {
               if(this.__proto__ === Vue.prototype) Vue.prototype.$droweyBus = this      
            },

            /**
             * @description
             * 1、将 @name 组件名 (创建组件时的 Name 属性)作为消息通知匹配的关键字
             * 2、若在组件中的 @callback method 中定义 @method receiveNotification ,则注册消息通知监听
             */
            beforeMount() {
                // this.componentName = this.__proto__ === Vue.prototype ?  'Vue' : (this.$vnode?.componentOptions?.tag || 'App')
                this.componentName = this.$options.name || 'Vue'
                if( this.receiveNotification ) this.$droweyBus.$on(this.componentName,this.receiveNotification) 
              
            },
            // 组件销毁前 移除监听
            beforeDestroy() {
                this.$droweyBus.$off(this.componentName)
            }
        })
        /**
         * @argument notificationToComponent 通知接收方  @String (组件名 componentName)
         * @argument data @Object 自定义通知内容 { notificationFrom： 通知发送方 , type： 通知类型（默认default）}
         * @description 定义发送通知方法,挂载至原型对象
         */
        Vue.prototype.pushNotification = function(notificationToComponent, data = {}){
            this.$droweyBus.$emit(notificationToComponent,{
                notificationFrom:this.componentName,
                type:'default',
                ...data
            })            
        }

    }
}