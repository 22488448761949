<template>
  <ScreenBoard title="验伪活跃省份排行" :rect="rect">
    <div class="scan-provinces">
      <div
        v-for="(province, index) in pvovincesData"
        :key="province.name"
        class="province"
        :style="provinceStyles[index]"
      >
        <div>{{ (province.percent*100).toFixed(1) }}%</div>
        <div>{{ province.name }}</div>
      </div>
    </div>
  </ScreenBoard>
</template>

<script>
import ScreenBoard from "@components/ScreenBoard";

export default {
  name: "ScreenScanProvince",
  props: {
    scanProvincesData: Array,
  },
  computed: {
    pvovincesData() {
      let total = this.scanProvincesData.reduce((sumTemp, currentProvince) => {
        return sumTemp + currentProvince.value;
      }, 0);
      let tempArray = this.scanProvincesData
        .sort((preItem, item) => {
          return item.value - preItem.value;
        })
        .slice(0, 6)
       

       return tempArray.map((item,index)=>{
          return {
            percent:item.value/total,
            scale: index > 3 ? 0.125:(6-index)/24,
            ...item
          }
        }).sort(() => {
          return Math.random() > 0.5 ? -1 : 1;
        });
    },

    provinceStyles() {

      return this.pvovincesData.map((item) => {
      
        return {
          width: `${480 * item.scale}px`,
          height: `${480 * item.scale}px`,
          background: `linear-gradient(to top,rgba(6, 232, 220, ${3 * item.scale}) , rgba(93, 131, 255, ${3 * item.scale}))`,
          "font-size": `${70 * item.scale}px`,
        };
      });
    },
  },
  mounted() {},
  data() {
    return {
      rect: {
        bottom: "80px",
        right: "56px",
        height: "265px",
        width: "540px",
      },
    };
  },
  components: {
    ScreenBoard,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.scan-provinces {
  // background-color: red;
  margin: 40px 22px;
  width: 480px;
  height: 130px;
  display: flex;
  align-items: center;

  .province {
    border-radius: 50%;
    color: white;
    text-align: center;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(red, green, blue, alpha);
  }
}
</style>



