<template>
  <ScreenBoard title="实时验伪用户流" :rect="rect">
    <div class="scan-logs">
      <transition-group
        appear
        tag="div"
        name="animate__animated animate__bounce "
        enter-active-class="animate__fadeInUp"
        leave-active-class="animate__fadeOutUp"
      >
        <div v-for="logData in logsData" class="log" :key="logData.id">
          <div class="log-detail">
            <div>
              用户 ** 对
              <span class="log-product">{{  logData.name?  logData.name : '**' }}</span> 进行了扫描验伪
            </div>
            {{ logData.timeStamp }}
          </div>
          <div>
            <i class="el-icon-location-outline" />
            <span class="log-address">
              {{ logData.address ? logData.address : "位置未知"}}</span
            >
          </div>
        </div>
      </transition-group>
    </div>
  </ScreenBoard>
</template>

<script>
import ScreenBoard from "@components/ScreenBoard";
import { nanoid } from "nanoid";
import moment from "moment";
export default {
  name: "ScreenScanLog",
  props: {
    scanLogsData: Array,
  },
  computed: {
    logsDataAll() {
      return this.scanLogsData.map((item) => {
        return {
          ...item,
          name:item.name?.length > 20 ?item.name.slice(0,20) + '...' : item.name,
          address:item.address?.length > 35 ?item.address.slice(0,35) + '...' : item.address,
          id: nanoid(),
          timeStamp: moment(item.verify_time).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
    },
  },
  mounted() {
    setInterval(() => {
      this.index++;
      if (this.index > 92) {
        this.index = 0;
      }
      this.logsData?.splice(0, 1);
      setTimeout(() => {
        this.logsData = this.logsDataAll?.slice(this.index, this.index + 7);
      }, 1000);
    }, 3000);
  },
  data() {
    return {
      rect: {
        top: "80px",
        right: "56px",
        height: "610px",
        width: "540px",
      },
      show: false,
      index: 0,
      logsData: this.logsDataAll?.slice(0, 7),
    };
  },
  components: {
    ScreenBoard,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.scan-logs {
  width: 92%;
  // background-color: aqua;
  height: calc(100% - 90px);
  color: white;
  font-size: 12px;
  margin: 4%;
  overflow: hidden;

  .log {
    height: 75px;
    line-height: 28px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    .log-detail {
      display: flex;
      justify-content: space-between;
    }

    .log-product {
      background-color: #5d83ff;
      padding: 1px 5px;
      margin: 0 8px;
    }
    .log-address {
      font-size: 11px;
      margin-left: 5px;
    }
  }
}
</style>



