/**
 * @ 页面控制
 * Drowey
 */

/**
 * [launchFullscreen 进入全屏]
 * @param  {[Object]} element [需要全屏的DOM元素]
 */
 export function launchFullscreen(docElm) {

    if (docElm.requestFullscreen) {
      docElm.requestFullscreen()
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen()
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen()
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen()
    }
  }
  
  /**
   * [exitFullscreen 退出全屏]
   */
  export function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
  }