<template>
  <div class="warp" ref="screen">
    <ScreenMap
      :mapCurrentData="mapTodayData"
      :mapHistoryData="mapYesterdayData"
    ></ScreenMap>
    <div class="data">
      <ScreenHead />
      <ScreenCount :count="labelCount" />
      <ScreenScanTrend :monthsData="monthsData" />
      <ScreenCustomerPortrait :customersData="customersData" />
      <ScreenScanLog :scanLogsData="scanLogsData" />
      <ScreenScanProvince :scanProvincesData="scanProvincesData" />
      <ScreenDescription></ScreenDescription>
    </div>
    <div
      :style="{ opacity: showTopControl ? 1 : 0 }"
      class="topControl"
      @mouseenter="showTopControl = true"
      @mouseleave="showTopControl = false"
    >
      <div class="title">沃朴科技 - <span>验伪行为数据大屏</span></div>
      <el-switch
        v-model="showFullScreen"
        @change="switchFullscreen"
        inactive-text="全屏开关"
        :width="50"
      >
      </el-switch>
    </div>
  </div>
</template>

<script>
import { launchFullscreen, exitFullscreen } from "@untils/fullScreen";
import ScreenCount from "@components/ScreenCount";
import ScreenHead from "@components/ScreenHead";
import ScreenScanTrend from "@components/ScreenScanTrend";
import ScreenCustomerPortrait from "@components/ScreenCustomerPortrait";
import ScreenScanLog from "@components/ScreenScanLog";
import ScreenScanProvince from "@components/ScreenScanProvince";
import ScreenMap from "@components/ScreenMap";
import ScreenDescription from "@components/ScreenDescription";

import { $Post, API } from "@network";

export default {
  name: "Screen",
  props: {
    msg: String,
  },
  methods: {
    switchFullscreen() {
      if (this.showFullScreen) {
        launchFullscreen(this.$refs.screen);
      } else {
        exitFullscreen();
      }
    },
    updateHistoryData(){
 $Post(API.UserPortrait)
        .then((res) => {
          let { ages, genders, province } = res.data;
          this.customersData = {
            customersAge: ages,
            customersSex: genders,
          };
          this.scanProvincesData = province;
        })
        .catch((err) => {
          console.log("Error", err);
        });

      $Post(API.scanTrend)
        .then((res) => {
          this.monthsData = res.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });

      $Post(API.labelCount)
        .then((res) => {
          this.labelCount = `${res.data[0].labelCount + 50000000}`;
        })
        .catch((err) => {
          console.log("Error", err);
        });

      $Post(API.yesterdayScanLogs)
        .then((res) => {
          // this.mapData.yesterdayData = res.data
          this.mapYesterdayData = res.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    updatePresenttData(){
 $Post(API.presentScanLog)
      .then((res) => {
        this.scanLogsData = res.data;
        this.mapTodayData = res.data;
      })
      .catch((err) => {
        console.log("Error", err);
      });
    }
  },
  data() {
    return {
      showTopControl: false,
      showFullScreen: false,
      mapTodayData: [],
      mapYesterdayData: [],
      labelCount: "",
      monthsData: [],
      customersData: {
        customersAge: [],
        customersSex: [],
      },
      scanLogsData: [],
      scanProvincesData: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.updateHistoryData()
       this.updatePresenttData()
    }, 2000);
    setInterval(() => {
      // console.log('执行一次数据吧')
      this.updateHistoryData()
    }, 1000*60*60*1);

    setInterval(()=>{
      this.updatePresenttData()
    }, 1000*60*5)



  },
  components: {
    ScreenCount,
    ScreenHead,
    ScreenScanTrend,
    ScreenCustomerPortrait,
    ScreenScanProvince,
    ScreenScanLog,
    ScreenMap,
    ScreenDescription,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;

  position: relative;
  .data {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      rgba(8, 12, 23, 0),
      rgba(8, 12, 23, 1)
    ); /* 标准的语法 */
  }
  .topControl {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10%;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #000000;
    padding: 30px;
    span {
      font-weight: 400;
    }
  }
}
</style>



