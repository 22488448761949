
// 详情信息接口
export const  Detail = 'wechat/product/productGoodsInfo';

// 登录接口
export const  Login = 'wechat/product/productGoodsInfo';

// 微信用户数据画像分析
export const  UserPortrait = 'wechat/getUserPortrait';
// 扫描趋势数据分析
export const  scanTrend = 'scan/getScanTrend';
// 标签绑定数据分析
export const  labelCount = 'scan/getLabelCount';
// 标签昨日扫描数据分析
export const  yesterdayScanLogs = 'scan/getYesterdayScanLogs';
// 标签实时扫描数据分析
export const  presentScanLog = 'scan/getPresentScanLogs';

