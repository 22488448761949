import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

import Login from "@pages/Login";
import Home from '@pages/Home'
import Board from '@pages/Board'
import Screen from '@pages/Screen'
import Report from '@pages/Report'

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            name: "WP",
            path: "/",
            meta: {
                title: '沃朴物联'
            },
            redirect: { name: 'login' },
        },
        {
            name:"login",
            path:"/login",
            meta:{
                title:'登录'
            },
            component: Login
        },
        {
            name:"home",
            path:"/home",
            meta:{
                title:'主页',
            },
   
            redirect: { name: 'screen' },
            
            component: Home,
            children:[
                {
                    name:"board",
                    path:"board",
                    meta:{
                        title:'数据看板',
                    },
                    component: Board,
                },
                {
                    name:"screen",
                    path:"screen",
                    meta:{
                        title:'数据大屏',
                    },
                    component: Screen,
                },
                {
                    name:"report",
                    path:"report",
                    meta:{
                        title:'数据看板',
                    },
                    component: Report,
                }
            ]
        }
    ]
})

// 路由前置处理
router.beforeEach((to, from, next) => {
    // console.log('******************', to.meta.needCheck, from)
    next()

})


// 路由后置处理

router.afterEach((to) => {
    // console.log('******************',to.meta,from)
    let { title } = to.meta
    document.title = title || '沃朴物联'

})


export default router