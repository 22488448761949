<template>
    <div class="warp">
        Board
    </div>

</template>

<script>




export default {
  name: "Board",
  props: {
    msg: String,
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.warp {
  width: 100%;
}
</style>
