<template>
  <div class="head">
    <el-image :src="require('@assets/logo.png')"></el-image>

    <div class="title"><span>WP-Screen</span></div>

    <div class="edit">
      <el-dropdown>
        <span class="el-dropdown-link">
          <i
            class="el-icon-setting"
            style="margin-right: 12px; color: white"
          ></i>
        </span>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>编辑</el-dropdown-item>
          <el-dropdown-item>查看</el-dropdown-item>
          <el-dropdown-item>删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span>沃朴物联</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Head",
  props: {
    title: String,
  },
  components: {},
  mounted() {
  
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.head {
  background-color: #091F2D;
  color: #fff;
  line-height: 60px;
  height: 60px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  .el-image {
    width: 205px;
    left: 20px;
    height: 16px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  .edit {
    display: flex;
    justify-content: flex-end;
    width: 225px;
    height: 60px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>