import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const actions = {}
const mutations = {}
const state = {
    num:0
}

// 创建导出 Store
export default new Vuex.Store({
    actions,
    mutations,
    state
})