<template>
  <el-aside width="180px">
    <el-menu
      :default-active= menuDefaultIndex
    >
      <el-menu-item
        @click="menuSelect(menu)"
        v-for="(menu, index) in menus"
        :key="menu.route"
        :index="`${index + 1}`"
      >
        <template slot="title"
          ><i :class="menu.icon"></i>{{ menu.title }}</template
        >
     </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
import MenuConfig from "@/router/menuConfig";

export default {
  name: "Side",
  props: {
    msg: String,
  },
  data: function () {
    return {
      menus: MenuConfig,
      menuDefaultIndex:'0'
    };
  },

  mounted() {
    // console.log(this.$router)
    let routName = this.$router.history.current.name;
    this.menus.forEach((menu, index) => {
        if (menu.route === routName) {
          this.menuDefaultIndex = `${index+1}`
        }
      });
  },
  methods: {
    menuSelect(item) {
      this.$router
        .push({
          name: item.route,
        })
        .catch(() => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-aside {
  color: #333;

  }
.el-menu {
  border: 0;
}
</style>