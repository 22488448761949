<template>
  <div class="count">
    <div class="count-title">标签绑定数</div>
    <div class="count-content">
      <div class="count-number" v-for="(number, index) in count" :key="index">
        <el-image
          :src="require('@assets/imgs/btn_scoreboard_bg.png')"
        ></el-image>
        <span>{{ number }}{{(index===2|| index ===5)?',':''}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenCount",
  props: {
    count: String,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.count {
  position: absolute;
  top:160px;
  height: 130px;
  width: 440px;
  left: calc(50% - 220px);
  background-color: rgba(0, 0, 0, 0.25);

  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;


  .count-title {
    // width: 500px;
  
    text-align: center;
    color: gray;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
  }
  .count-content {
    display: flex;
    .count-number {
      position: relative;
      span {
        // font-family: Arial;
        // font-family: Helvetica;
        font-size: 48px;
        position: absolute;
        display: block;
        width: 100%;
        font-weight: 800;
        bottom: -4px;
        left: 9px;
        color: white;
      }
    }
  }
}
</style>



